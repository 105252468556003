import React from 'react';
import {Grid} from '@mui/material';

import Head from "../components/commons/Head";
import {IContactWording} from "../models/json/IContactWording";
import ContactItem from "../components/contact/ContactItem";

const contactWordings: IContactWording = require("../assets/configuration/wordings/contact.json");

const ContactView = () => {

    return <>
        <Head title={contactWordings.title} description={contactWordings.description}/>

        <Grid container spacing={2} sx={{marginTop: '40px'}}>
            {contactWordings.items.map(item =>
                <Grid item key={item.description} xs={12} md={6} lg={4} xl={4}>
                    <ContactItem type={item.type} description={item.description} value={item.value}
                                 link={item.link} image={item.image}/>
                </Grid>)
            }
        </Grid>
    </>
}

export default ContactView;