import React from 'react';
import {Button as MuiButton, ButtonProps, styled} from '@mui/material';

const ColorButton = styled(MuiButton)<ButtonProps>(() => ({
    backgroundColor: '#bb4430',
    '&:hover': {
        backgroundColor: '#7c1603',
    },
}));

const PressedColorButton = styled(MuiButton)<ButtonProps>(() => ({
    backgroundColor: '#7c1603',
    '&:hover': {
        backgroundColor: '#bb4430',
    },
}));

interface IButtonProps {
    label: string;
    onClick: any;
    press?: boolean;
}

const Button = (props: IButtonProps) => {

    const {label, onClick, press} = props;

    return <>
        {!press &&
            <ColorButton variant="contained" sx={{fontFamily: 'IBMPlexMono', margin: '2px', padding: '6px'}}
                         onClick={onClick}>
                {label}
            </ColorButton>
        }
        {press &&
            <PressedColorButton variant="contained" sx={{fontFamily: 'IBMPlexMono', margin: '2px', padding: '6px'}}
                                onClick={onClick}>
                {label}
            </PressedColorButton>
        }
    </>
}

export default Button;