import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';

import {IMenuItem} from "../../models/IMenuItem";
import {IExternalLink} from "../../models/IExternalLink";
import TerminusLogo from "../../assets/images/logo.jpg";
import MenuItem from "./MenuItem";
import ExternalLink from "./ExternalLink";

interface INavbarProps {
    menuItems: IMenuItem[];
    externalLinks: IExternalLink[];
}

const Navbar = (props: INavbarProps) => {

    const {menuItems, externalLinks} = props;

    return <>
        <AppBar position="static" sx={{height: 64, backgroundColor: '#bb4430'}}>
            <Container maxWidth="xl" sx={{height: 64}}>
                <Toolbar disableGutters sx={{height: 64}}>

                    <Box sx={{height: 64, marginRight: '30px'}}>
                        <img src={TerminusLogo} alt="Logo du Terminus Brass Band"
                             style={{height: '100%'}}/>
                    </Box>

                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}, height: 64}}>
                        {menuItems.filter(item => item.display != false).map(item =>
                            <MenuItem key={item.path} path={item.path} name={item.name}/>)
                        }
                    </Box>

                    <Box sx={{flexGrow: 0, display: {xs: 'none', md: 'flex'}, height: 64}}>
                        {externalLinks.map(link =>
                            <ExternalLink key={link.name} name={link.name} url={link.url} icon={link.icon}/>)
                        }
                    </Box>

                </Toolbar>
            </Container>
        </AppBar>
    </>
}

export default Navbar;