import React, {useState} from 'react';
import {Tooltip} from '@mui/material';
import Link from '@mui/material/Link';

import {IExternalLink} from "../../models/IExternalLink";


const ExternalLink = (props: IExternalLink) => {

    const {name, url, icon} = props;
    const [color, setColor] = useState('#bb4430');

    return <>
        <Tooltip title={'Page ' + name + ' du Terminus Brass Band'}>
            <Link href={url} target={'_blank'} className={'transition'}
                  style={{paddingLeft: 12, paddingRight: 12, textDecoration: 'none', backgroundColor: color}}
                  onMouseOver={() => setColor('#a61e05')}
                  onMouseOut={() => setColor('#bb4430')}>

                <div style={{lineHeight: '64px', fontSize: 32, color: '#ffffff'}}>
                    {icon}
                </div>

            </Link>
        </Tooltip>
    </>
}

export default ExternalLink;