import React from 'react';
import Box from '@mui/material/Box';
import {Typography} from '@mui/material';

import Text from "./Text";

interface IHeadProps {
    title: string;
    subtitle?: string;
    description?: string;
}

const Head = (props: IHeadProps) => {

    const {title, subtitle, description} = props;

    return <>
        <Box sx={{width: '100%'}}>
            <Typography variant="h1" gutterBottom
                        fontFamily={'Steamy'} fontSize={42}>
                {title}
            </Typography>

            {subtitle &&
                <Typography variant="h2" gutterBottom
                            fontFamily={'AllertaStencil'} fontSize={32}>
                    {subtitle}
                </Typography>
            }

            {description &&
                <Text text={description}/>
            }
        </Box>
    </>
}

export default Head;