import React from 'react';
import Box from '@mui/material/Box';

import Head from "../components/commons/Head";

const WelcomeView = () => {

    return <>
        <Head
            title={'LE TERMINUS BRASS BAND'}
            subtitle={'Fanfare nantaise pour événements dix verres.'}
        />

        <Box>
            C’est au crépuscule d’une Excellente aventure fanfaronne que des rescapés virent apparaître, à travers la
            brume d’une friche nantaise, une flamboyante locomotive vêtue de rouge et d’or. Ils n’eurent alors qu’une
            idée en tête : grimper à bord et remettre en marche son moteur rutilant.
            C’était sans se douter des incroyables facultés de la machine, capable de transporter les voyageurs passant
            par son wagon-bar à travers le temps et les univers parallèles. Entre révolution industrielle et monde de
            Jules Verne, en passant par des époques troubles marquées par le syndicalisme ou l’augmentation du prix des
            transports, les membres de l’équipée parvinrent à recruter de nouveaux adeptes de tous horizons pour former
            une fanfare haute en couleurs : le Terminus Brass Band.
            Que vous soyez de Nantes ou d’ailleurs, n’hésitez pas à demander un arrêt de la loco du Terminus Brass Band
            près de vos établissements de débit de boissons, de chamalows ou autres cacahuètes grillées. Nous aimons
            également faire escale lors de vos rassemblements villageois, bar-mitzvahs et festivals pour partager un
            moment musical et dynamique qui vous donnera l’Envie de repartir avec nous.
            Et n’oubliez pas : “Vous êtes tous génials !”
        </Box>
    </>
}

export default WelcomeView;