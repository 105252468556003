import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Typography} from '@mui/material';

import {IMenuItem} from "../../models/IMenuItem";

const MenuItem = (props: IMenuItem) => {

    const {path, name} = props;
    const [color, setColor] = useState('#bb4430');

    return <>
        <Link to={path} className={'transition'}
              style={{paddingLeft: 12, paddingRight: 12, textDecoration: 'none', backgroundColor: color}}
              onMouseOver={() => setColor('#a61e05')}
              onMouseOut={() => setColor('#bb4430')}>

            <Typography variant="h6" component="div"
                        fontFamily={'AllertaStencil'} fontSize={16} color={'#ffffff'}
                        style={{flex: 1, justifyContent: 'center', alignItems: "center", lineHeight: '64px'}}>
                {name}
            </Typography>

        </Link>
    </>
}

export default MenuItem;