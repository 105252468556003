import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Box, Grid} from '@mui/material';

import {IWording} from "../models/json/IWording";
import {IFanfaron} from "../models/IFanfaron";
import Head from "../components/commons/Head";
import Fanfaron from "../components/fanfarons/Fanfaron";
import Button from "../components/commons/Button";
import FanfaronDialog from "../components/fanfarons/FanfaronDialog";

const fanfaronsWordings: IWording = require("../assets/configuration/wordings/fanfarons.json");
const fanfaronsDatabase: IFanfaron[] = require("../assets/configuration/database/fanfarons.json");

const FanfaronsView = () => {

    const {id} = useParams();

    const [fanfarons, setFanfarons] = useState([] as IFanfaron[]);
    const [fanfaron, setFanfaron] = useState({} as IFanfaron);
    const [categories] = useState([] as string[]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setFanfarons(shuffleFanfarons());

        if (id) {
            const f = filterFanfaronByNickname(id);
            if (f.nickname) {
                setFanfaron(f);
                setOpen(true);
            }
        }
    }, []);

    const shuffleFanfarons = () => {
        return fanfaronsDatabase.slice().sort(() => 0.5 - Math.random());
    }

    const openDialog = (fanfaron: IFanfaron) => {
        setFanfaron(fanfaron);
        setOpen(true);
    }

    const momo = () => openDialog(filterFanfaronByNickname('momo'));
    const randomFanfaron = () => openDialog(fanfaronsDatabase[Math.floor(Math.random() * fanfaronsDatabase.length)]);

    const filterFanfaronByNickname = (nickname: string): IFanfaron => {
        const filteredFanfaron = fanfaronsDatabase.filter(f => f.nickname.toLowerCase() === nickname.toLowerCase());
        return filteredFanfaron.length > 0 ? filteredFanfaron[0] : {} as IFanfaron;
    }

    const filterFanfaronsByCategory = (category: string) => {

        categories.includes(category) ?
            categories.splice(categories.indexOf(category), 1) :
            categories.push(category);

        categories.length === 0 ?
            setFanfarons(shuffleFanfarons()) :
            setFanfarons(shuffleFanfarons().filter(f => categories.includes(f.category)))
    }

    const handleClose = () => {
        setOpen(false);
    };

    return <>
        <Head title={fanfaronsWordings.title} description={fanfaronsWordings.description}/>

        <Box sx={{display: 'flex', marginTop: '30px'}}>
            <Button label={'👑 Momo'} onClick={() => momo()}/>
            <Button label={'🔀 Aléatoire'} onClick={() => randomFanfaron()}/>
        </Box>

        <Box sx={{display: 'flex'}}>
            <Button label={'🎧 Aigus'} onClick={() => filterFanfaronsByCategory("AIGU")} press={categories.includes("AIGU")}/>
            <Button label={'🎷 Petits saxs'} onClick={() => filterFanfaronsByCategory("PETIT_SAX")} press={categories.includes("PETIT_SAX")}/>
            <Button label={'🎷🎷 Gros saxs'} onClick={() => filterFanfaronsByCategory("GROS_SAX")} press={categories.includes("GROS_SAX")}/>
            <Button label={'🎺 Trompettes'} onClick={() => filterFanfaronsByCategory("TROMPETTE")} press={categories.includes("TROMPETTE")}/>
            <Button label={'🎺🎺 Trombones'} onClick={() => filterFanfaronsByCategory("TROMBONE")} press={categories.includes("TROMBONE")}/>
            <Button label={'🎺🎺🎺 Tubas'} onClick={() => filterFanfaronsByCategory("TUBA")} press={categories.includes("TUBA")}/>
            <Button label={'🎺🎺🎺🎺 Soubas'} onClick={() => filterFanfaronsByCategory("SOUBA")} press={categories.includes("SOUBA")}/>
            <Button label={'🥁 Percus'} onClick={() => filterFanfaronsByCategory("PERCU")} press={categories.includes("PERCU")}/>
        </Box>

        <Grid container spacing={2} sx={{marginTop: '30px'}}>
            {fanfarons.map(f =>
                <Grid item key={f.nickname} xs={6} md={4} lg={3} xl={3}>
                    <Fanfaron fanfaron={f} openDialog={openDialog}/>
                </Grid>)
            }
        </Grid>

        {open && <FanfaronDialog fanfaron={fanfaron} open={open} onClose={handleClose} momo={momo} randomFanfaron={randomFanfaron}/>}
    </>
}

export default FanfaronsView;