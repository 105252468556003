import React from 'react';
import {Typography} from '@mui/material';
import Box from '@mui/material/Box';

const DEFAULT_TYPE = 'default';
const COLOR_TYPE = 'color';
const COLOR_BEGIN = '<co>';
const COLOR_END = '</co>'
const LINE_BREAK = '<br/>'

interface ITextProps {
    text: string;
    fontSize?: number;
}

const Text = (props: ITextProps) => {

    const {text, fontSize} = props;

    return <>
        <Box sx={{textAlign: 'justify', display: 'flex', alignItems: 'center'}}>
            <Box>
                {lineBreak(text).map(line =>
                    <Typography key={line} variant="h3" gutterBottom
                                fontFamily={'IBMPlexMono'} fontSize={fontSize ? fontSize : 18}>

                        {parseText(line).map(item =>
                            <span key={item.value}
                                  style={{
                                      color: item.key == COLOR_TYPE ? '#bb4430' : '#000000',
                                      fontWeight: item.key == COLOR_TYPE ? 'bold' : ''
                                  }}>
                                {item.value}
                            </span>)
                        }
                    </Typography>)
                }
            </Box>
        </Box>
    </>
}

/**
 *  Retour à la ligne.
 */
const lineBreak = (t: string): string[] => {
    return t.split(LINE_BREAK);
}

/**
 *  Parsing du texte.
 */
const parseText = (t: string): { key: string, value: string }[] => {

    const items: { key: string, value: string }[] = [];

    t.split(COLOR_BEGIN).map(a => {
        let color = true;
        a.split(COLOR_END).map(b => {
            if (color) {
                items.push({key: COLOR_TYPE, value: b});
                color = false;
            } else {
                items.push({key: DEFAULT_TYPE, value: b});
            }
        });
    });

    if (!t.startsWith(COLOR_BEGIN)) items[0].key = DEFAULT_TYPE;

    return items;
}

export default Text;