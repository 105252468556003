import React from 'react';
import {Card, CardActionArea, CardContent, CardMedia, Typography} from '@mui/material';
import Box from '@mui/material/Box';

import {IContactItem} from "../../models/json/IContactWording";

const ContactItem = (props: IContactItem) => {

    const {type, description, value, link, image} = props;

    return <>
        <Card sx={{height: '100%'}}>
            <CardActionArea sx={{height: '100%'}}>

                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <CardMedia sx={{width: '70%'}} component="img" image={image} alt={description}/>
                </Box>

                <CardContent>
                    <Typography gutterBottom variant="h5" component="div" width={'100%'} align={'center'}
                                fontFamily={'AllertaStencil'} fontSize={20}>
                        {description}
                    </Typography>

                    <Typography variant="body2" color="text.secondary" width={'100%'} align={'center'}
                                fontFamily={'IBMPlexMono'} fontSize={18}>
                        {value}
                    </Typography>
                </CardContent>

            </CardActionArea>
        </Card>
    </>
}

export default ContactItem;