import React from 'react';
import {Box, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, Typography} from '@mui/material';

import {IFanfaron} from "../../models/IFanfaron";
import Text from "../commons/Text";
import Button from "../commons/Button";

interface IFanfaronDialogProps {
    fanfaron: IFanfaron;
    open: boolean;
    onClose: any;
    momo: any;
    randomFanfaron: any;
}

const FanfaronDialog = (props: IFanfaronDialogProps) => {

    const {fanfaron, open, onClose, momo, randomFanfaron} = props;

    const handleClose = () => {
        onClose();
    };

    return <>
        <Dialog onClose={handleClose} open={open} maxWidth={'md'}>

            <DialogTitle sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Typography gutterBottom component="div" fontFamily={'AllertaStencil'} fontSize={30}>
                    {fanfaron.nickname}
                </Typography>
                <Typography gutterBottom component="div" fontSize={45} sx={{marginLeft: '20px'}}>
                    {fanfaron.animal}
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={2}>

                    <Grid item xs={4} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Box sx={{filter: 'sepia(0.4)'}}>
                            <img src={fanfaron.photo} alt={'Photo ' + fanfaron.nickname} style={{width: '90%'}}/>
                        </Box>
                    </Grid>

                    <Grid item xs={8} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <DialogContentText>
                            <Box>
                                <Text text={fanfaron.description} fontSize={16}/>
                            </Box>

                            <Box sx={{display: 'flex', justifyContent: 'right', marginTop: '30px'}}>
                                <Button label={'👑 Momo'} onClick={() => momo()}/>
                                <Button label={'🔀 Aléatoire'} onClick={() => randomFanfaron()}/>
                            </Box>
                        </DialogContentText>
                    </Grid>

                </Grid>
            </DialogContent>

        </Dialog>
    </>
}

export default FanfaronDialog;