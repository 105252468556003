import React, {useState} from 'react';
import {Box, Card, CardActionArea, CardContent, CardMedia, Typography} from '@mui/material';

import {IFanfaron} from "../../models/IFanfaron";

const animations = [
    {animation: 'rotation', pictos: ['hourglass', 'clock']},
    {animation: 'horizontal-translation', pictos: ['zeppelin']},
    {animation: 'vertical-translation', pictos: ['hot-air-balloon']}
];

interface IFanfaronProps {
    fanfaron: IFanfaron;
    openDialog: any;
}

const Fanfaron = (props: IFanfaronProps) => {

    const {fanfaron, openDialog} = props;

    const [animation, setAnimation] = useState('');

    const handleMouseOver = (picto: string) => {
        if (animations.flatMap(a => a.pictos).includes(picto)) {
            animations.forEach(a => {
                if (a.pictos.includes(picto)) {
                    setAnimation(a.animation);
                }
            });
        } else {
            setAnimation('zoom');
        }
    }

    const handleMouseOut = () => {
        setAnimation('default');
    }

    return <>
        <Card sx={{height: '100%'}}
              onMouseOver={() => handleMouseOver(fanfaron.picto.name)}
              onMouseOut={() => handleMouseOut()}
              onClick={() => openDialog(fanfaron)}>

            <CardActionArea sx={{height: '100%'}}>

                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', filter: 'sepia(0.4)'}}>
                    <CardMedia sx={{width: '100%'}} component="img" image={fanfaron.photo}
                               alt={'Photo ' + fanfaron.nickname}/>
                </Box>

                <CardContent sx={{display: 'flex'}}>
                    <Typography gutterBottom variant="h5" component="div" width={'100%'} align={'center'}
                                fontFamily={'AllertaStencil'} fontSize={25}>
                        {fanfaron.nickname}
                    </Typography>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <img style={{width: '40px'}} className={animation} src={fanfaron.picto.path}
                             alt={'Picto ' + fanfaron.nickname}/>
                    </Box>
                </CardContent>

            </CardActionArea>
        </Card>
    </>
}

export default Fanfaron;