import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {Grid} from '@mui/material';
import {FaFacebookSquare, FaSoundcloud} from 'react-icons/fa';
import {GrInstagram} from 'react-icons/gr';
import {TfiYoutube} from 'react-icons/tfi';

import {IMenuItem} from "./models/IMenuItem";
import {IExternalLink} from "./models/IExternalLink";
import Navbar from "./components/navbar/Navbar";
import WelcomeView from "./views/WelcomeView";
import FanfaronsView from "./views/FanfaronsView";
import ContactView from "./views/ContactView";


const menuItems: IMenuItem[] = [
    {path: '/', name: 'ACCUEIL', element: <WelcomeView/>},
    {path: '/fanfarons', name: 'FANFARONS', element: <FanfaronsView/>},
    {path: '/fanfarons/:id', name: 'FANFARONS', element: <FanfaronsView/>, display: false},
    {path: '/contact', name: 'CONTACT', element: <ContactView/>}
];

const externalLinks: IExternalLink[] = [
    {name: 'Facebook', url: 'https://www.facebook.com/terminusbrassband', icon: <FaFacebookSquare/>},
    {name: 'Instagram', url: 'https://www.instagram.com/terminusbrassband', icon: <GrInstagram/>},
    {name: 'Youtube', url: 'https://www.youtube.com/channel/UCnT15j859s1VY8FLNrqR4sg', icon: <TfiYoutube/>},
    {name: 'Soundcloud', url: 'https://soundcloud.com/terminusbrassband', icon: <FaSoundcloud/>}
];

const App = () => {

    return <>
        <BrowserRouter>
            <Navbar menuItems={menuItems} externalLinks={externalLinks}/>

            <Grid container spacing={2}
                  style={{marginTop: 40, marginBottom: 40, paddingLeft: 80, paddingRight: 80}}>
                <Grid item xs={12}>
                    <Routes>
                        {menuItems.map(item =>
                            <Route key={item.path} path={item.path} element={item.element}/>)
                        }
                    </Routes>
                </Grid>
            </Grid>
        </BrowserRouter>
    </>
}

export default App;
